import React, { useEffect } from 'react';
import hive from "./index"
import { useState } from "@hookstate/core"
import LotCard from './LotCard';
import HiveSlider from './HiveSlider';
import { project } from "./project/project"


function TypeFilter() {
    const { height, mainColor, searchResult, totalWidth } = useState(hive.state)
    const searchFilter = useState(hive.searchFilter)
    const additionelSearchFilter = useState(hive.additonalSearchFilter)

    return <>
        {searchFilter.filter(e => e.get().enabled && e.get().name !== "T5" && e.get().name !== "T6").map((e: any, i: number) =>
            <div key={i}
                onClick={() => {
                    e.active.set((v: any) => !v)
                    hive.applyFilter()
                }}
                style={{
                    userSelect: "none",
                    cursor: "pointer",
                    width: height.get() * 0.05,
                    height: height.get() * 0.05,
                    borderRadius: height.get() * 0.007,
                    textAlign: "center",
                    lineHeight: height.get() * 0.05 + "px",
                    // background: e.active.get() ? `#${mainColor.get()}` : "white",
                    background: `rgb(${e.background[0].get()},${e.background[1].get()},${e.background[2].get()})`,
                    color: "white",
                    marginLeft: height.get() * 0.01,
                    
                    opacity: e.active.get() ? "1" : "0.4",

                    // opacity: e.active.get() ? "1" : (searchFilter.get().find((f: any) => {
                    //     return f.active
                    // }) ? "0.4" : "1"),

                    transition: "opacity 0.2s",
                }}
            >
                {e.name.get()}
            </div>
        )}

        <div style={{
            width: height.get() * 0.015,
        }}>
        </div>

        {additionelSearchFilter.filter(e => e.get().enabled).map((e: any, i: number) =>

            <div key={i}
                onClick={() => {
                    e.active.set((v: any) => !v)
                    hive.applyFilter()
                }}
                style={{
                    userSelect: "none",
                    cursor: "pointer",
                    // width: height.get() * 0.05,
                    paddingLeft: height.get() * 0.015,
                    paddingRight: height.get() * 0.015,
                    height: height.get() * 0.05,
                    borderRadius: height.get() * 0.007,
                    textAlign: "center",
                    lineHeight: height.get() * 0.05 + "px",
                    // background: e.active.get() ? `#${mainColor.get()}` : "white",
                    background: `rgb(${e.background[0].get()},${e.background[1].get()},${e.background[2].get()})`,
                    color: "white",
                    marginLeft: height.get() * 0.01,
                    opacity: e.active.get() ? "1" : "0.4",
                    transition: "opacity 0.2s",
                }}
            >
                {e.name.get()}
            </div>
        )}

    </>
}
export default TypeFilter
