export const leftMenuBase = [
    // {
    //     text: "Skyview",
    //     action: "setScene",
    //     target: "sky",
    //     icon: "plane"
    // },
    {
        text: "Aerien",
        action: "setScene",
        target: "Roof",
        icon: "plane"
    },
    // {
    //     text: "R+4",
    //     action: "setScene",
    //     target: "F5"
    // },
    {
        text: "R+3",
        action: "setScene",
        target: "F4"
    },
    {
        text: "R+2",
        action: "setScene",
        target: "F3"
    },
    {
        text: "R+1",
        action: "setScene",
        target: "F2"
    },
    {
        text: "Entresol",
        action: "setScene",
        target: "F1"
    },
    {
        text: "RDC",
        action: "setScene",
        target: "F0"
    },
    // {
    //     text: "Sous-sol",
    //     action: "setScene",
    //     target: "F1"
    // },
]

export const leftMenuTour = [
    {
        text: "Sky",
        action: "setScene",
        target: "sky",
        icon: "plane"
    },
    {
        text: "T3",
        action: "setScene",
        target: "t3",
    },
]

export const bot_menu_sky = [
    {
        text: "Sky",
        action: "setScene",
        target: "sky"
    },
    {
        text: "T3",
        action: "setScene",
        target: "t3"
    },

]

export const bot_menu_plan_plan = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_pp_plan"
    },
    {
        text: "Plan R+1",
        action: "setLayout",
        target: "poi_pp_plan_2"
    },

]

export const leftMenuHouse = [
    {
        text: "Toit",
        action: "setOrbit",
        target: "%orbit%"
    },
    {
        text: "R+1",
        action: "setOrbit",
        target: "%orbit%_Floor2"
    },
    {
        text: "RDC",
        action: "setOrbit",
        target: "%orbit%_Floor1"
    },
]

export const bot_menu_plan = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_p_plan"
    },
]

export const bot_menu_plan_orbit_axo = [
    {
        text: "Plan",
        action: "setLayout",
        target: "p_poa_plan"
    },
    {
        text: "Orbital",
        action: "setLayout",
        target: "p_poa_orbit"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "p_poa_axo"
    },

]

export const bot_menu_plan_orbit_axo_axo = [
    {
        text: "Plan",
        action: "setLayout",
        target: "p_poaa_plan"
    },
    {
        text: "Orbital",
        action: "setLayout",
        target: "p_poaa_orbit"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "p_poaa_axo"
    },
    {
        text: "Plan 3D R+1",
        action: "setLayout",
        target: "p_poaa_axo_2"
    },
]

export const bot_menu_plan_orbit = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_po_plan"
    },
    {
        text: "Orbital",
        action: "setLayout",
        target: "poi_po_orbit"
    },
]

export const bot_menu_plan_axo = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_pa_plan"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "poi_pa_axo"
    },
]

export const bot_menu_plan_iframe = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_pi_plan"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "poi_pi_iframe"
    },
]

export const bot_menu_plan_axo_iframe = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_pai_plan"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "poi_pai_axo"
    },
    {
        text: "Visite 360",
        action: "setLayout",
        target: "poi_pai_iframe"
    },
]

export const bot_menu_plan_axo_orbit_tour = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_paot_plan"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "poi_paot_axo"
    },
    {
        text: "360",
        action: "setLayout",
        target: "poi_paot_orbit"
    },
    {
        text: "Visite 360",
        action: "setLayout",
        target: "poi_paot_tour"
    },
]


export const bot_menu_plan_axo_tour = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_pat_plan"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "poi_pat_axo"
    },
    {
        text: "Visite 360",
        action: "setLayout",
        target: "poi_pat_tour"
    },
]

export const bot_menu_plan_plan_axo_axo = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_ppaa_plan"
    },
    {
        text: "Plan R+1",
        action: "setLayout",
        target: "poi_ppaa_plan_2"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "poi_ppaa_axo"
    },
    {
        text: "Plan 3D R+1",
        action: "setLayout",
        target: "poi_ppaa_axo_2"
    },
]

export const bot_menu_plan_planLA = [
    {
        text: "LA1",
        action: "setLayout",
        target: "poi_ppLA_plan"
    },
    {
        text: "LA2",
        action: "setLayout",
        target: "poi_ppLA_plan_2"
    },

]

export const bot_menu_plan_planBU = [
    {
        text: "BU2",
        action: "setLayout",
        target: "poi_ppBU_plan"
    },
    {
        text: "BU3",
        action: "setLayout",
        target: "poi_ppBU_plan_2"
    },

]

export const _bot_menu_plan_axo_axo = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_paa_plan"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "poi_paa_axo"
    },
    {
        text: "Plan 3D R+1",
        action: "setLayout",
        target: "poi_paa_axo_2"
    },

]

export const bot_menu_plan_axo_axo_axo_axo = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_paaaa_plan"
    },
    {
        text: "Plan 3D A",
        action: "setLayout",
        target: "poi_paaaa_axo"
    },
    {
        text: "Plan 3D A R+1",
        action: "setLayout",
        target: "poi_paaaa_axo_2"
    },
    {
        text: "Plan 3D B",
        action: "setLayout",
        target: "poi_paaaa_axo_3"
    },
    {
        text: "Plan 3D B R+1",
        action: "setLayout",
        target: "poi_paaaa_axo_4"
    },
]

export const bot_menu_plan_axo_axo_axo = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_paaa_plan"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "poi_paaa_axo"
    },
    {
        text: "Plan 3D R-1",
        action: "setLayout",
        target: "poi_paaa_axo_2"
    },
    {
        text: "Plan 3D R+1",
        action: "setLayout",
        target: "poi_paaa_axo_3"
    },
]


export const bot_menu_plan_axo_axo = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_paa_plan"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "poi_paa_axo"
    },
    {
        text: "Plan 3D R+1",
        action: "setLayout",
        target: "poi_paa_axo_2"
    },

]

export const bot_menu_plan_plan_axo = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_ppa_plan"
    },
    {
        text: "Plan R+1",
        action: "setLayout",
        target: "poi_ppa_plan_2"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "poi_ppa_axo"
    },

]

export const bot_menu_all = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_plan"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "poi_axo"
    },
    {
        text: "Visite 360",
        action: "setLayout",
        target: "poi_virtual"
    },
    {
        text: "Orbital",
        action: "setLayout",
        target: "orbit_T5A"
    }

]
